import { CarShopLabels, ShopCar, ShopOfferFOL } from '../../utilities/car-shop/car-shop-types'
import { getLocaleString, Locale } from '../../utilities/general/locale'
import React, { useContext } from 'react'
import styled from 'styled-components'

interface CarShopPriceTableTakeoverProps {
    carShopLabels: CarShopLabels
    shopCar: ShopCar
}

const CarShopPriceTableTakeover = ({ carShopLabels, shopCar }: CarShopPriceTableTakeoverProps): JSX.Element => {
    const locale = useContext(Locale)
    const localeString = getLocaleString(locale)
    const dateTimeOptions = { year: 'numeric', month: 'long', day: 'numeric' }
    const offer = shopCar.offers.find(offer => offer.__typename === 'CarShop_ShopOfferFOL')
    const prices = ((offer as ShopOfferFOL)?.prices || []).sort(
        (priceA, priceB) => priceA.priceExVAT - priceB.priceExVAT
    )

    return (
        <div>
            <Introduction>
                {carShopLabels.priceTableContractTakeoverText.replace(
                    /\{\{\s*contractEndDate*\s\}\}/g,
                    Intl.DateTimeFormat(localeString, dateTimeOptions).format(new Date(shopCar.contractEndDate))
                )}
            </Introduction>
            <Table>
                <TableCaption>{carShopLabels.excludingTax}</TableCaption>
                <tbody>
                    {prices.map((price, index) => (
                        <tr key={index}>
                            <TableHeading>
                                {Intl.NumberFormat(localeString).format(price.mileage)}{' '}
                                {carShopLabels.kilometersAbbreviation}
                            </TableHeading>
                            <TableCell>{price.priceExVAT}</TableCell>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <AdditionalInfo
                dangerouslySetInnerHTML={{ __html: carShopLabels.priceTableContractTakeoverMoreInformation }}
            />
        </div>
    )
}

export default CarShopPriceTableTakeover

const Introduction = styled.p`
    color: var(--color-black-light);
    font-size: var(--font-size-extra-small);
    margin: 1rem 0 1.125rem;

    @media (min-width: 31rem) {
        font-size: var(--font-size-small);
    }
`

const Table = styled.table`
    width: 100%;
    font-size: var(--font-size-extra-small);
    border-collapse: collapse;

    @media (min-width: 23rem) {
        font-size: var(--font-size-small);
    }

    @media (min-width: 31rem) {
        font-size: var(--font-size);
    }
`

const TableCaption = styled.caption`
    caption-side: bottom;
    color: var(--color-grey-extra-dark);
    font-size: var(--font-size-extra-small);
    text-align: right;
    margin: 0.125rem 0 0;
`

const TableHeading = styled.th`
    font-weight: var(--font-weight-regular);
    text-align: center;
    padding: 0.125rem 0.375rem;
    border: 0.0625rem solid #e0e0e0;

    &[colspan] {
        border: 0;
        font-size: var(--font-size-extra-small);
    }

    &:not(:first-child):not([colspan]) {
        width: 15%;
    }
`

const TableCell = styled.td`
    opacity: 0.6;
    text-align: right;
    padding: 0.125rem 0.375rem;
    border: 0.0625rem solid #e0e0e0;
`

const AdditionalInfo = styled.div`
    padding: 0.75rem 0 0;
    border-top: 0.0625rem solid #e0e0e0;
    margin: 1.375rem 0 0;

    p {
        color: var(--color-grey-extra-dark);
        font-size: var(--font-size-extra-small);
        line-height: 1.4;
        text-align: center;
        margin: 0;

        & + p {
            margin-top: 0.75rem;
        }

        a {
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        @media (min-width: 31rem) {
            font-size: var(--font-size-small);
        }
    }
`
