import { CarModel, Tenant } from '../general/types'
import { RedriveCarCondition, RedrivePricePerKm, RedrivePricingTable, ShopOfferEnum } from './car-shop-types'

export const redrivePricePerKmTable: RedrivePricePerKm = {
    [CarModel.MODEL_3]: {
        [ShopOfferEnum.fol]: 0.15,
        [ShopOfferEnum.privateLease]: 0.18,
    },
    [CarModel.MODEL_S]: {
        [ShopOfferEnum.fol]: 0.2,
        [ShopOfferEnum.privateLease]: 0.24,
    },
    [CarModel.MODEL_X]: {
        [ShopOfferEnum.fol]: 0.2,
        [ShopOfferEnum.privateLease]: 0.24,
    },
    [CarModel.MODEL_Y]: {
        [ShopOfferEnum.fol]: 0.15,
        [ShopOfferEnum.privateLease]: 0.18,
    },
}

export const redrivePricingTable: RedrivePricingTable = {
    [Tenant.NL]: {
        [CarModel.MODEL_S]: [
            {
                title: 'Model S',
                edition: '75D (ZGAN)',
                condition: RedriveCarCondition.AS_NEW,
                fromPrice: {
                    [ShopOfferEnum.fol]: 589,
                    [ShopOfferEnum.privateLease]: 719,
                },
                withAsterix: true,
            },
            {
                title: 'Model S',
                edition: '75D (LG)',
                condition: RedriveCarCondition.USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 485,
                    [ShopOfferEnum.privateLease]: 589,
                },
                withAsterix: true,
            },
            {
                title: 'Model S',
                edition: '75D (ZG)',
                condition: RedriveCarCondition.HEAVILY_USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 449,
                    [ShopOfferEnum.privateLease]: 549,
                },
                withAsterix: true,
            },
        ],
        [CarModel.MODEL_X]: [
            {
                title: 'Model X',
                edition: '75D (ZGAN)',
                condition: RedriveCarCondition.AS_NEW,
                fromPrice: {
                    [ShopOfferEnum.fol]: 679,
                    [ShopOfferEnum.privateLease]: 821,
                },
                withAsterix: true,
            },
            {
                title: 'Model X',
                edition: '75D (LG)',
                condition: RedriveCarCondition.USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 629,
                    [ShopOfferEnum.privateLease]: 761,
                },
                withAsterix: true,
            },
            {
                title: 'Model X',
                edition: '75D (ZG)',
                condition: RedriveCarCondition.HEAVILY_USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 579,
                    [ShopOfferEnum.privateLease]: 700,
                },
                withAsterix: true,
            },
        ],
        [CarModel.MODEL_3]: [
            {
                title: 'Model 3',
                edition: 'Standard Range',
                fromPrice: {
                    [ShopOfferEnum.fol]: 329,
                    [ShopOfferEnum.privateLease]: 399,
                },
                withAsterix: true,
            },
            {
                title: 'Model 3',
                edition: 'Long Range',
                fromPrice: {
                    [ShopOfferEnum.fol]: 379,
                    [ShopOfferEnum.privateLease]: 449,
                },
                withAsterix: true,
            },
            {
                title: 'Model 3',
                edition: 'Performance',
                fromPrice: {
                    [ShopOfferEnum.fol]: 419,
                    [ShopOfferEnum.privateLease]: 499,
                },
                withAsterix: true,
            },
        ],
        [CarModel.MODEL_Y]: [
            {
                title: 'Model Y',
                edition: 'RWD',
                fromPrice: {
                    [ShopOfferEnum.fol]: 529,
                    [ShopOfferEnum.privateLease]: 641,
                },
                withAsterix: true,
            },
            {
                title: 'Model Y',
                edition: 'Long Range',
                fromPrice: {
                    [ShopOfferEnum.fol]: 619,
                    [ShopOfferEnum.privateLease]: 749,
                },
                withAsterix: true,
            },
            {
                title: 'Model Y',
                edition: 'Performance',
                fromPrice: {
                    [ShopOfferEnum.fol]: 669,
                    [ShopOfferEnum.privateLease]: 810,
                },
                withAsterix: true,
            },
        ],
    },
    [Tenant.DE]: {
        [CarModel.MODEL_3]: [
            {
                title: 'Model 3 RWD',
                fromPrice: {
                    [ShopOfferEnum.fol]: 549,
                },
            },
            {
                title: 'Model 3 LR',
                fromPrice: {
                    [ShopOfferEnum.fol]: 589,
                },
            },
            {
                title: 'Model 3 P',
                fromPrice: {
                    [ShopOfferEnum.fol]: 619,
                },
            },
        ],
        [CarModel.MODEL_Y]: [
            {
                title: 'Model Y RWD',
                fromPrice: {
                    [ShopOfferEnum.fol]: 599,
                },
            },
            {
                title: 'Model Y LR',
                fromPrice: {
                    [ShopOfferEnum.fol]: 659,
                },
            },
            {
                title: 'Model Y P',
                fromPrice: {
                    [ShopOfferEnum.fol]: 709,
                },
            },
        ],
        [CarModel.MODEL_S]: [
            {
                title: 'Model S 75D',
                condition: RedriveCarCondition.AS_NEW,
                fromPrice: {
                    [ShopOfferEnum.fol]: 639,
                },
                withAsterix: true,
            },
            {
                title: 'Model S 75D',
                condition: RedriveCarCondition.USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 589,
                },
                withAsterix: true,
            },
            {
                title: 'Model S 75D',
                condition: RedriveCarCondition.HEAVILY_USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 539,
                },
                withAsterix: true,
            },
        ],
        [CarModel.MODEL_X]: [
            {
                title: 'Model X 75D',
                condition: RedriveCarCondition.AS_NEW,
                fromPrice: {
                    [ShopOfferEnum.fol]: 689,
                },
                withAsterix: true,
            },
            {
                title: 'Model X 75D',
                condition: RedriveCarCondition.USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 639,
                },
                withAsterix: true,
            },
            {
                title: 'Model X 75D',
                condition: RedriveCarCondition.HEAVILY_USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 589,
                },
                withAsterix: true,
            },
        ],
    },
    [Tenant.BE]: {
        [CarModel.MODEL_S]: [
            {
                title: 'Model S 75D',
                condition: RedriveCarCondition.AS_NEW,
                fromPrice: {
                    [ShopOfferEnum.fol]: 639,
                },
            },
            {
                title: 'Model S 75D',
                condition: RedriveCarCondition.USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 589,
                },
            },
            {
                title: 'Model S 75D',
                condition: RedriveCarCondition.HEAVILY_USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 539,
                },
            },
            {
                title: 'Model S 100D',
                condition: RedriveCarCondition.AS_NEW,
                fromPrice: {
                    [ShopOfferEnum.fol]: 839,
                },
            },
            {
                title: 'Model S 100D',
                condition: RedriveCarCondition.USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 789,
                },
            },
            {
                title: 'Model S 100D',
                condition: RedriveCarCondition.HEAVILY_USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 739,
                },
            },
        ],
        [CarModel.MODEL_X]: [
            {
                title: 'Model X 75D',
                condition: RedriveCarCondition.AS_NEW,
                fromPrice: {
                    [ShopOfferEnum.fol]: 689,
                },
            },
            {
                title: 'Model X 75D',
                condition: RedriveCarCondition.USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 639,
                },
            },
            {
                title: 'Model X 75D',
                condition: RedriveCarCondition.HEAVILY_USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 589,
                },
            },
            {
                title: 'Model X 100D',
                condition: RedriveCarCondition.AS_NEW,
                fromPrice: {
                    [ShopOfferEnum.fol]: 889,
                },
            },
            {
                title: 'Model X 100D',
                condition: RedriveCarCondition.USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 839,
                },
            },
            {
                title: 'Model X 100D',
                condition: RedriveCarCondition.HEAVILY_USED,
                fromPrice: {
                    [ShopOfferEnum.fol]: 789,
                },
            },
        ],
        [CarModel.MODEL_3]: [
            {
                title: 'Model 3 RWD',
                fromPrice: {
                    [ShopOfferEnum.fol]: 599,
                },
            },
            {
                title: 'Model 3 LR',
                fromPrice: {
                    [ShopOfferEnum.fol]: 649,
                },
            },
            {
                title: 'Model 3 Perf',
                fromPrice: {
                    [ShopOfferEnum.fol]: 719,
                },
            },
        ],
        [CarModel.MODEL_Y]: [
            {
                title: 'Model Y RWD',
                fromPrice: {
                    [ShopOfferEnum.fol]: 629,
                },
            },
            {
                title: 'Model Y LR',
                fromPrice: {
                    [ShopOfferEnum.fol]: 669,
                },
            },
            {
                title: 'Model Y Perf',
                fromPrice: {
                    [ShopOfferEnum.fol]: 739,
                },
            },
        ],
    },
}

export const getPricingTable = (tenant: Tenant, model: CarModel) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!Reflect.has(redrivePricingTable, tenant) || !Reflect.has(redrivePricingTable[tenant], model)) {
        throw new Error(`No pricing table found for tenant: ${tenant.toString()} and model: ${model.toString()}`)
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return redrivePricingTable[tenant][model]
}

export const getPricePerKmValue = (carmodel: CarModel, offerType: ShopOfferEnum) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return redrivePricePerKmTable[carmodel][offerType]
}
