import { CarShopLabels, ShopCar } from '../../utilities/car-shop/car-shop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import ChargingIcon from '../ui/charging-icon'

// eslint-disable-next-line
// @ts-ignore
import imageFallback from '../../assets/tesla-logo.png'

const CarShopDetailImage = ({
    carShopLabels,
    shopCar,
}: {
    carShopLabels: CarShopLabels
    shopCar: ShopCar
}): JSX.Element => (
    <ImageContainer>
        {shopCar.superCharging === 'transferable' && (
            <ImageBanner>
                <ChargingIcon />
                free supercharging
            </ImageBanner>
        )}
        <Image
            src={shopCar.mainImageUrl || imageFallback}
            alt=""
            loading="lazy"
            useEnlargedVersion={shopCar.model.name.toLowerCase().endsWith('x') && !!shopCar.mainImageUrl}
        />
        {shopCar.availability !== 'AVAILABLE' && (
            <ImageOverlay>
                {shopCar.availability === 'RESERVED'
                    ? carShopLabels.availabilityStateReserved
                    : carShopLabels.availabilityStateNotAvailable}
            </ImageOverlay>
        )}
    </ImageContainer>
)

export default CarShopDetailImage

const ImageContainer = styled.div`
    position: relative;
    overflow: hidden;
    background-color: var(--color-white);
    padding: 1rem 0 0;
    margin: 3rem 0 1.25rem;
    display: flex;
    align-items: center;

    @media (min-width: 26rem) {
        padding: 0;
    }

    @media (min-width: 56rem) {
        margin: 0;
    }
`

const ImageBanner = styled.p`
    position: absolute;
    top: 0.625rem;
    right: 0.75rem;
    color: rgba(33, 33, 33, 0.5);
    font-size: var(--font-size-extra-small);
    margin: 0;
    display: flex;
    align-items: center;

    svg {
        height: 1.25rem;
        width: auto;
        color: #ef473a;
        margin-right: 0.375rem;
    }
`

const Image = styled.img<{ useEnlargedVersion?: boolean }>`
    height: auto;
    width: 100%;

    ${props =>
        props.useEnlargedVersion &&
        css`
            width: calc(100% + 20rem);
            margin: auto -10rem;
        `}

    @media (min-width: 63rem) {
        width: calc(100% + 20rem);
        margin: auto -10rem;

        ${props =>
            props.useEnlargedVersion &&
            css`
                width: calc(100% + 40rem);
                margin: auto -20rem;
            `}
    }
`

const ImageOverlay = styled.p`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(96, 180, 194, 0.8);
    color: var(--color-white);
    font-weight: 500;
    text-shadow: 0 0 0.3125rem #60b4c2;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`
