import { CarShopLabels, ShopCar, ShopOfferFOL, ShopOfferPrivateLease } from '../../utilities/car-shop/car-shop-types'
import getAvailableMatrixKeys from '../../utilities/car-shop/get-available-matrix-keys'
import { getLocaleString, Locale } from '../../utilities/general/locale'
import getMileagePrices from '../../utilities/car-shop/get-mileage-prices'
import React, { useContext } from 'react'
import styled, { css } from 'styled-components'

interface CarShopPriceTableMatrixProps {
    carShopLabels: CarShopLabels
    offer: ShopOfferFOL | ShopOfferPrivateLease
    shopCar: ShopCar
}

const CarShopPriceTableMatrix = ({ carShopLabels, offer, shopCar }: CarShopPriceTableMatrixProps): JSX.Element => {
    const locale = useContext(Locale)
    const localeString = getLocaleString(locale)
    const { availableDurations, availableMileages } = getAvailableMatrixKeys(shopCar)

    return (
        <Table>
            <TableCaption>
                {offer.__typename === 'CarShop_ShopOfferFOL' ? carShopLabels.excludingTax : carShopLabels.includingTax}
            </TableCaption>
            <thead>
                <tr>
                    <TableHeading isEmpty={true}></TableHeading>
                    <TableHeading colSpan={availableDurations.length}>
                        {carShopLabels.durationInMonthsText}
                    </TableHeading>
                </tr>
                <tr>
                    <TableHeading isEmpty={true}></TableHeading>
                    {availableDurations.map(duration => (
                        <TableHeading key={duration}>{duration}</TableHeading>
                    ))}
                </tr>
            </thead>
            <tbody>
                {availableMileages.map(mileage => {
                    const mileagePrices = getMileagePrices({
                        prices: (offer as ShopOfferFOL | ShopOfferPrivateLease).prices,
                        mileage,
                        typename: (offer as ShopOfferFOL | ShopOfferPrivateLease).__typename,
                    })

                    return (
                        <tr key={mileage}>
                            <TableHeading>
                                {Intl.NumberFormat(localeString).format(mileage)} {carShopLabels.kilometersAbbreviation}
                            </TableHeading>
                            {mileagePrices.map((price, index) => (
                                <TableCell key={index}>{Intl.NumberFormat(localeString).format(price)}</TableCell>
                            ))}
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}

export default CarShopPriceTableMatrix

const Table = styled.table`
    width: 100%;
    font-size: var(--font-size-extra-small);
    border-collapse: collapse;

    @media (min-width: 23rem) {
        font-size: var(--font-size-small);
    }

    @media (min-width: 31rem) {
        font-size: var(--font-size);
    }
`

const TableCaption = styled.caption`
    caption-side: bottom;
    color: var(--color-grey-extra-dark);
    font-size: var(--font-size-extra-small);
    text-align: right;
    margin: 0.125rem 0 0;
`

const TableHeading = styled.th<{ isEmpty?: boolean }>`
    font-weight: var(--font-weight-regular);
    text-align: center;
    padding: 0.125rem 0.375rem;
    border: 0.0625rem solid #e0e0e0;

    &[colspan] {
        border: 0;
        font-size: var(--font-size-extra-small);
    }

    &:not(:first-child):not([colspan]) {
        width: 15%;
    }

    ${props =>
        props.isEmpty &&
        css`
            border: 0;
        `}
`

const TableCell = styled.td`
    opacity: 0.6;
    text-align: right;
    padding: 0.125rem 0.375rem;
    border: 0.0625rem solid #e0e0e0;
`
