import { ShopCar, ShopOfferFOL, ShopOfferPrivateLease } from './car-shop-types'

interface AvailableMatrixKeys {
    availableDurations: number[]
    availableMileages: number[]
}

export const matrixTypes = ['CarShop_ShopOfferFOL', 'CarShop_ShopOfferPrivateLease']

export default function getAvailableMatrixKeys(shopCar: ShopCar): AvailableMatrixKeys {
    const firstMatrixType = shopCar.offers.find(offer => matrixTypes.includes(offer.__typename))

    const availableDurations = (firstMatrixType as ShopOfferFOL | ShopOfferPrivateLease).prices
        .map(({ duration }) => duration)
        .filter((duration, index, array) => array.indexOf(duration) === index)
        .sort((durationA, durationB) => durationA - durationB)

    const availableMileages = (firstMatrixType as ShopOfferFOL | ShopOfferPrivateLease).prices
        .map(({ mileage }) => mileage)
        .filter((mileage, index, array) => array.indexOf(mileage) === index)
        .sort((mileageA, mileageB) => mileageA - mileageB)

    return { availableDurations, availableMileages }
}
