import React from 'react'
import styled from 'styled-components'
import { SpecData, SpecsList, SpecTitle } from './car-shop-detail-specs'

const CarShopPriceTableR2S = (): JSX.Element => {
    return (
        <>
            <TopContainer>
                <TitleLarge>
                    <span> R2S Full operational lease </span>
                </TitleLarge>
                <div>
                    <TitleSmall>
                        <span> Vanaf 15000km per jaar</span>
                    </TitleSmall>
                    <TitleExtraSmall>
                        <span>(incl winterbanden en laadkosten)</span>
                    </TitleExtraSmall>
                </div>
            </TopContainer>
            <BottomContainer>
                <SpecsList>
                    <SpecTitle>Model S 75D / 19" </SpecTitle>
                    <SpecData> vanaf € 1030.65* </SpecData>
                    <SpecTitle>Model X 75D / 20"</SpecTitle>
                    <SpecData>vanaf € 1080.65* </SpecData>
                    <SpecTitle>Model S 100D / 19"</SpecTitle>
                    <SpecData>vanaf € 1180.65* </SpecData>
                    <SpecTitle>Model X 100D / 20"</SpecTitle>
                    <SpecData>vanaf € 1230.65* </SpecData>
                </SpecsList>
                <Title> Meerprijs uitvoering: </Title>
                21"(Model S) of 22" (Model X) velgen = € 50 <br />
                FSD op auto = € 35 <br />
                <TitleExtraSmall>
                    <span>
                        *Prijzen zijn ex btw en per maand KM contracten voor delers kan je vinden op Mobi2Share <br />
                        <a href="//www.mobi2share.nl">www.mobi2share.nl</a>
                    </span>
                </TitleExtraSmall>
            </BottomContainer>
        </>
    )
}

export default CarShopPriceTableR2S

const TitleExtraSmall = styled.div`
    font-family: var(--font-headings);
    font-size: var(--font-size-extra-small);
    font-weight: var(--font-weight-medium);
    color: var(--color-grey);
    text-transform: uppercase;
    margin-top: 1rem;

    span:last-of-type {
        display: block;

        @media (min-width: 26rem) {
            display: inline;
        }
    }
`

const BottomContainer = styled.div`
    max-width: 40rem;
    padding-bottom: 1.5rem;
    margin: 0 auto;

    @media print {
        margin-top: 2rem;
        break-after: page;
    }
`

const TopContainer = styled.div`
    max-width: 40rem;
    padding: 1rem 0;
    margin: 0 auto;

    @media print {
        break-after: page;
    }
`

const TitleLarge = styled.h1`
    font-family: var(--font-headings);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    margin: 0;

    span:last-of-type {
        display: block;

        @media (min-width: 26rem) {
            display: inline;
        }
    }
`

const TitleSmall = styled.h1`
    font-family: var(--font-headings);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
    margin-top: 1rem;

    span:last-of-type {
        display: block;

        @media (min-width: 26rem) {
            display: inline;
        }
    }
`

const Title = styled.h1`
    font-family: var(--font-headings);
    font-size: var(--font-size);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    margin: 0;

    span:last-of-type {
        display: block;

        @media (min-width: 26rem) {
            display: inline;
        }
    }
`
