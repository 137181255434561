import { CarShopSinglePageData } from '../../utilities/create-pages/fetch-car-shop-data'
import Logo from '../ui/logo'
import React from 'react'
import styled from 'styled-components'

interface CarShopPrintHeaderProps {
    carShopSinglePageData: CarShopSinglePageData
}

const CarShopPrintHeader = ({ carShopSinglePageData }: CarShopPrintHeaderProps): JSX.Element => {
    return (
        <Container>
            <Logo />
            <QrContainer>
                <img
                    src={
                        typeof window !== 'undefined'
                            ? `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${window.location}`
                            : ''
                    }
                    alt=""
                />
                <div dangerouslySetInnerHTML={{ __html: carShopSinglePageData.qrCodeAccompanyingText }} />
            </QrContainer>
        </Container>
    )
}

export default CarShopPrintHeader

const Container = styled.div`
    display: none;

    @media print {
        display: flex;
        margin-top: 2rem;
    }

    > :first-child {
        display: block;
        height: auto;
        width: 100%;
        max-width: 500px;
        margin: 0 auto 32px;

        @media print {
            width: 50%;
            margin-top: 15px;
        }
    }
`

const QrContainer = styled.div`
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;

    @media print {
        width: 700px;
        transform: scale(0.7);
    }

    img {
        display: block;
        margin-right: 20px;
    }

    > div {
        * {
            margin: 0 0 0.75rem;
        }
    }
`
