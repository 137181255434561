import React, { useContext } from 'react'
import styled from 'styled-components'
import { SpecData, SpecsList, SpecTitle } from './car-shop-detail-specs'
import { ShopOfferEnum } from '../../utilities/car-shop/car-shop-types'
import { CarDetailContext, CarShopContext } from './car-shop-context'
import { CarModel, Tenant } from '../../utilities/general/types'
import { getPricePerKmValue, getPricingTable } from '../../utilities/car-shop/redrive-pricing-table'
import { convertCarModelToEnum } from '../../utilities/car-shop/convert-car-model-to-enum'
import { getRedriveLabels } from '../../utilities/car-shop/redrive-labels'
import { Locale } from '../../utilities/general/locale'

// eslint-disable-next-line
// @ts-ignore
import private_lease_keurmerk from '../../assets/private_lease_keurmerk.png'

const CarShopPriceTableReDrive = () => {
    const locale = useContext(Locale)
    const { labels, language } = useContext(CarShopContext)
    const { offerType, car: shopCar } = useContext(CarDetailContext)

    const tenant = locale.tenant as Tenant
    const carModel = convertCarModelToEnum(shopCar.model.id)
    const redriveLabels = getRedriveLabels(language)
    const pricingTable = getPricingTable(tenant, carModel) || []
    const leaseLabel = offerType === ShopOfferEnum.fol ? labels.offerTypeFolName : labels.offerTypePrivateLeaseName
    const priceperKm = getPricePerKmValue(carModel, offerType)
    const pricingLabels: string[] = []

    const labelsForModelsSX = () => {
        switch (tenant) {
            case Tenant.DE:
                return offerType === ShopOfferEnum.fol
                    ? `€200 ${redriveLabels.perMonthLabel}`
                    : `€242 ${redriveLabels.perMonthLabel}`
            default:
                return offerType === ShopOfferEnum.fol
                    ? `€200 ${labels.excludingTaxAbbreviation} ${redriveLabels.perMonthLabel}`
                    : `€242 ${labels.includingTaxAbbreviation} ${redriveLabels.perMonthLabel}`
        }
    }

    const labelsForModels3Y = () => {
        switch (tenant) {
            case Tenant.DE:
                //NOTE: Hardcoded string for DE per Kees and Erik request
                return `SR/RWD & Long Range €25\n ${redriveLabels.moreKmLabel} = Performance €30`
            case Tenant.BE:
                //NOTE: Hardcoded string for BE per Kees and Erik request
                return carModel === CarModel.MODEL_3 || carModel === CarModel.MODEL_Y
                    ? `RWD & LR --> 25 € \n Performance --> 35€`
                    : `€50 ${labels.excludingTaxAbbreviation} ${redriveLabels.perMonthLabel}`
            default:
                return offerType === ShopOfferEnum.fol
                    ? `€50 ${labels.excludingTaxAbbreviation} ${redriveLabels.perMonthLabel}`
                    : `€60,20 ${labels.includingTaxAbbreviation} ${redriveLabels.perMonthLabel}`
        }
    }

    const footNote = (offerType: ShopOfferEnum) => {
        const footnote = redriveLabels.footnote
        if (!footnote) {
            return ''
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return footnote[offerType] as string
    }

    return (
        <>
            {offerType === ShopOfferEnum.privateLease && (
                <PrivateLeaseKeurmerk
                    style={{
                        width: '100px',
                        boxShadow: '8px 11px 12px 3px rgb(17 1 1 / 20%)',
                        marginTop: '-40px',
                    }}
                    src={private_lease_keurmerk}
                />
            )}
            <TopContainer>
                <TitleLarge>
                    <span> ReDrive | {leaseLabel}</span>
                </TitleLarge>
                <div>
                    <TitleSmall>
                        <span>
                            {tenant === Tenant.DE
                                ? `Startpreis basierend auf 48 Monate / 10.000 km`
                                : tenant === Tenant.NL
                                ? carModel !== CarModel.MODEL_S && ''
                                : `${redriveLabels.fromLabel} 10.000km ${redriveLabels.perYearLabel}`}
                        </span>
                    </TitleSmall>
                </div>
            </TopContainer>
            <BottomContainer>
                <SpecsList>
                    <>
                        {pricingTable.map(({ title, edition, fromPrice, withAsterix }, i) => {
                            const normalizedTitle = pricingLabels.find(priceLabel => priceLabel === title) ? '' : title
                            if (normalizedTitle != '') {
                                pricingLabels.push(title)
                            }

                            return (
                                <>
                                    <SpecTitle>{normalizedTitle}</SpecTitle>
                                    <SpecData>
                                        {edition} {redriveLabels.fromLabel} €
                                        {
                                            fromPrice[
                                                offerType === ShopOfferEnum.privateLease
                                                    ? 'CarShop_ShopOfferPrivateLease'
                                                    : 'CarShop_ShopOfferFOL'
                                            ]
                                        }
                                        {tenant === Tenant.DE && redriveLabels.perMonthLabel}
                                        {withAsterix && '*'}
                                    </SpecData>
                                </>
                            )
                        })}
                    </>
                </SpecsList>
                {(carModel === CarModel.MODEL_S || carModel === CarModel.MODEL_X) && (
                    <>
                        <b> {tenant !== Tenant.BE && `${redriveLabels.edition100DLabel} =  ${labelsForModelsSX()}`} </b>
                        <br />
                    </>
                )}
                <br />
                {tenant === Tenant.NL && (
                    <div>
                        *Vanafprijzen op basis van een contract voor 60 maanden en 10.000 km per jaar.
                        <br />
                        {offerType === ShopOfferEnum.privateLease
                            ? `Meerprijs per km: ${priceperKm} ct incl. BTW`
                            : `Meerprijs per km: ${priceperKm} ct excl. BTW`}
                    </div>
                )}
                <TitleSmall>{footNote(offerType)}</TitleSmall>
            </BottomContainer>
        </>
    )
}

export default CarShopPriceTableReDrive

const BottomContainer = styled.div`
    max-width: 40rem;
    padding-bottom: 1rem;
    margin: 0 auto;

    @media print {
        margin-top: 2rem;
        break-after: page;
    }
`

const TopContainer = styled.div`
    max-width: 40rem;
    padding: 1rem 0;
    margin: 0 auto;

    @media print {
        break-after: page;
    }
`

const TitleLarge = styled.h1`
    font-family: var(--font-headings);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    margin: 0;

    span:last-of-type {
        display: block;

        @media (min-width: 26rem) {
            display: inline;
        }
    }
`

const TitleSmall = styled.h1`
    font-family: var(--font-headings);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
    margin-top: 1rem;

    span:last-of-type {
        display: block;

        @media (min-width: 26rem) {
            display: inline;
        }
    }
`

const Title = styled.h1`
    font-family: var(--font-headings);
    font-size: var(--font-size);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    margin: 0;

    span:last-of-type {
        display: block;

        @media (min-width: 26rem) {
            display: inline;
        }
    }
`

const PrivateLeaseKeurmerk = styled.img`
    width: '100px',
    box-shadow: '8px 11px 12px 3px rgb(17 1 1 / 20%)',
    margin-top: '-40px',
`
