import { CarModel } from '../general/types'

export const convertCarModelToEnum = (carModel: string) => {
    const model = carModel.toLowerCase().replace(/[\W_]/g, '')
    switch (model) {
        case 'models':
            return CarModel.MODEL_S
        case 'modelx':
            return CarModel.MODEL_X
        case 'model3':
            return CarModel.MODEL_3
        case 'modely':
            return CarModel.MODEL_Y
        default:
            return CarModel.MODEL_S
    }
}
