import React, { useContext } from 'react'
import styled from 'styled-components'
import { CarDetailContext, CarShopContext } from './car-shop-context'
import { getRedriveLabels } from '../../utilities/car-shop/redrive-labels'
import { getRedriveFormLink } from '../../utilities/car-shop/redrive'

export const RequestRedriveQuoteButton = (): JSX.Element => {
    const { language, tenant } = useContext(CarShopContext)
    const { car, offerType } = useContext(CarDetailContext)

    const link = getRedriveFormLink(tenant, language, offerType)
    const redriveLabels = getRedriveLabels(language)

    return (
        <MoreInformationButton href={`${link}?occasion_license_plate=${car.licensePlate}`} target="_blank">
            {redriveLabels.requestQuoteLabel}
        </MoreInformationButton>
    )
}

const MoreInformationButton = styled.a`
    display: block;
    background-image: var(--gradient-green);
    color: var(--color-white);
    text-align: center;
    padding: 1rem 2rem;
    border-radius: 3rem;
    transition: opacity 0.1s ease-in-out;

    &:hover {
        opacity: 0.8;
    }
`
