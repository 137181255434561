import { createContext } from 'react'

import { Language, Tenant } from '../../utilities/general/types'
import { CarShopLabels, ShopCar, ShopOfferEnum } from '../../utilities/car-shop/car-shop-types'

export type CarShopContextType = {
    labels: CarShopLabels
    supportsRedrive: boolean
    language: Language
    tenant: Tenant
}

export type CarDetailContextType = {
    car: ShopCar
    offerType: ShopOfferEnum
}

export const CarShopContext = createContext({} as CarShopContextType)

export const CarDetailContext = createContext({} as CarDetailContextType)
