import { ShopOfferFOL, ShopOfferMatrixPrice, ShopOfferPrivateLease } from './car-shop-types'

interface GetMileagePricesParam {
    mileage: number
    prices: ShopOfferMatrixPrice[]
    typename: ShopOfferFOL['__typename'] | ShopOfferPrivateLease['__typename']
}

export default function getMileagePrices({ mileage, prices, typename }: GetMileagePricesParam): number[] {
    let mileagePriceObjects = prices.filter((price: ShopOfferMatrixPrice) => price.mileage === mileage)
    mileagePriceObjects = mileagePriceObjects.sort((priceA, priceB) => priceA.duration - priceB.duration)
    return mileagePriceObjects.map(price =>
        typename === 'CarShop_ShopOfferFOL' ? price.priceExVAT : price.priceIncVAT
    )
}
