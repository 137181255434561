import { CarShopLabels, ShopCar } from './car-shop-types'
import getPriceStringParts from './get-price-string-parts'
import { LocaleObject } from '../general/types'
import { makeTitleCase } from '../general/labels'
import { SeoProps } from '../craft-data/prepare-seo-props'

interface UpdateShopCarMetaTagsParam {
    carShopLabels: CarShopLabels
    locale: LocaleObject
    seoProps: SeoProps
    shopCar: ShopCar
}

export default function updateShopCarMetaTags({
    carShopLabels,
    locale,
    seoProps,
    shopCar,
}: UpdateShopCarMetaTagsParam): SeoProps {
    seoProps.title = `${makeTitleCase(`${shopCar.brand.name} ${shopCar.model.name}`)} | MisterGreen Electric Lease`
    seoProps.image = shopCar.mainImageUrl
    seoProps.description = `Occasion - ${makeTitleCase(
        `${shopCar.brand.name} ${shopCar.model.name}`
    )} - ${getPriceStringParts({ carShopLabels, locale, offerTypeName: 'CarShop_ShopOfferFOL', shopCar }).join(' ')}`

    return seoProps
}
