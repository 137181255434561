/* Custom MisterGreen interfaces */

export interface BaseLocaleObject {
    site: string
    siteId: number
    siteType: string
    tenant: string
    country: string
    language: string
    languagePrettyName: string
    baseUrl: string
    imageUrl: string
    localeIndependentCountryName: string
    buildTimeFormIds: string[]
    hasCheckout: boolean
}

export interface LocaleObject extends BaseLocaleObject {
    currencyCode: string
}

export interface MisterGreenConfig {
    allLocales: BaseLocaleObject[]
    locales: BaseLocaleObject[]
}

/* Craft GraphQL API interfaces */

// TODO: Implement that CraftData interface mirrors the GraphQL query within the Page component.
export interface CraftData {
    allCraftEntryInterface: {
        nodes: CraftEntry[]
    }
}

export interface CraftEntry {
    typeHandle: string
    slug: string
    [key: string]: any
}

export interface CraftGlobalSet {
    handle: string
    [key: string]: any
}

export enum Tenant {
    NL = 'mistergreen_nl',
    BE = 'mistergreen_be',
    DE = 'mistergreen_de',
    DK = 'mistergreen_dk',
    PT = 'mistergreen_pt',
    ES = 'mistergreen_es',
    FR = 'mistergreen_fr',
}

export enum Language {
    NL = 'nl',
    BE = 'be',
    DE = 'de',
    DK = 'dk',
    PT = 'pt',
    ES = 'es',
    FR = 'fr',
    EN = 'en',
}

export enum CarModel {
    MODEL_3 = 'model3',
    MODEL_S = 'models',
    MODEL_X = 'modelx',
    MODEL_Y = 'modely',
}

/* React interfaces */

export interface DefaultProps {
    children: JSX.Element[] | JSX.Element
}
