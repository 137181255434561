import { Language, Tenant } from '../general/types'
import { ShopOfferEnum } from './car-shop-types'

export const redriveTenants = [Tenant.NL, Tenant.DE, Tenant.BE]
export const redriveOffers = [ShopOfferEnum.fol, ShopOfferEnum.privateLease]
export const tenantSupportsRedrive = (tenant: Tenant) => {
    return redriveTenants.includes(tenant)
}

export const isRedriveOffer = (tenant: Tenant, offer: ShopOfferEnum) => {
    return redriveTenants.includes(tenant) && redriveOffers.includes(offer)
}

type RedriveFormLink = {
    offer?: ShopOfferEnum
    tenant: Tenant
    language: string
    link: string
}

export const redriveFormLinks: RedriveFormLink[] = [
    {
        offer: ShopOfferEnum.fol,
        tenant: Tenant.NL,
        language: Language.NL,
        link: 'https://share.hsforms.com/1ZK8DJN2rRf25v7D_jFZpUw23gm8',
    },
    {
        offer: ShopOfferEnum.privateLease,
        tenant: Tenant.NL,
        language: Language.NL,
        link: 'https://share.hsforms.com/17z95le0vSgaoWgHIJvBw-Q23gm8',
    },
    {
        tenant: Tenant.BE,
        language: Language.NL,
        link: 'https://share.hsforms.com/1FHH600MqT6iapry6Dy7MYg23gm8',
    },
    {
        tenant: Tenant.BE,
        language: Language.FR,
        link: 'https://share.hsforms.com/1WFK_czpbTvuFGFFrN3ELFA23gm8',
    },
    {
        tenant: Tenant.DE,
        language: Language.DE,
        link: 'https://share.hsforms.com/1hEheG2FBQd28YTy4BxQydg23gm8',
    },
]

export const getRedriveFormLink = (tenant: Tenant, language: Language, offer: ShopOfferEnum) => {
    const link = redriveFormLinks.find(
        link => link.tenant === tenant && link.language === language && link.offer === offer
    )

    if (link) {
        return link.link
    }

    return redriveFormLinks.find(link => link.tenant === tenant && link.language === language)?.link
}
