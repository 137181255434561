import { CarShopType, CarShopLabels } from '../../utilities/car-shop/car-shop-types'
import getCarShopRootPath from '../../utilities/car-shop/get-car-shop-root-path'
import getShopOfferTypes from '../../utilities/car-shop/get-shop-offer-types'
import { Link } from 'gatsby'
import { LocaleObject } from '../../utilities/general/types'
import { makeOverviewQueryString } from '../../utilities/car-shop/filters-query-string'
import queryString from 'query-string'
import React from 'react'
import styled from 'styled-components'

interface CarShopBackLinkProps {
    availableShopOfferTypes: string[]
    carShopLabels: CarShopLabels
    carShopType: CarShopType
    locale: LocaleObject
    location: any
}

const CarShopBackLink = ({
    availableShopOfferTypes,
    carShopLabels,
    carShopType,
    locale,
    location,
}: CarShopBackLinkProps): JSX.Element => {
    const currentShopOfferType = getShopOfferTypes(availableShopOfferTypes).find(({ name }) => {
        return `CarShop_${name}` === queryString.parse(location.search).offerType
    })

    const carShopRootPath = getCarShopRootPath({ carShopType, locale })
    const newQueryString = makeOverviewQueryString(location.search)
    const offerTypeUrlSegment = currentShopOfferType ? currentShopOfferType.url : ''
    const backUrl = `${carShopRootPath}/${offerTypeUrlSegment}${newQueryString}`

    return (
        <BackLink to={backUrl}>
            <SVG aria-hidden="true">
                <path
                    fill="currentColor"
                    d="M14.6,7 L11.3,10.3 C10.9,10.7 10.9,11.3 11.3,11.7 C11.5,11.9 11.7,12 12,12 C12.3,12 12.5,11.9 12.7,11.7 L17.7,6.7 C17.9,6.5 18,6.25 18,6 C18,5.75 17.9,5.5 17.7,5.3 L12.7,0.3 C12.3,-0.1 11.7,-0.1 11.3,0.3 C10.9,0.7 10.9,1.3 11.3,1.7 L14.6,5 L1,5 C0.4,5 0,5.4 0,6 C0,6.6 0.4,7 1,7 L14.6,7 Z"
                    transform="rotate(180 9 6)"
                ></path>
            </SVG>
            {carShopLabels.backToOverviewLinkText}
        </BackLink>
    )
}

export default CarShopBackLink

const BackLink = styled(Link)`
    max-width: max-content;
    color: #636363;
    font-size: var(--font-size);
    font-weight: var(--font-weight-medium);
    margin: 1rem 0;
    display: flex;
    align-items: center;
    transition: opacity 0.1s ease-in-out;

    &:hover {
        opacity: 0.6;
    }
`

const SVG = styled.svg`
    height: 0.875rem;
    width: 1.125rem;
    line-height: 1;
    margin-right: 0.5rem;
`
