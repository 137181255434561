import CarShopAdditionalInfo from '../car-shop/car-shop-additional-info'
import CarShopBackground from '../car-shop/car-shop-background'
import CarShopBackLink from '../car-shop/car-shop-back-link'
import CarShopDetailImage from '../car-shop/car-shop-detail-image'
import CarShopDetailSpecs from '../car-shop/car-shop-detail-specs'
import CarShopFeatures from '../car-shop/car-shop-features'
import { CarShopLabels, CarShopType, ShopCar, ShopOfferEnum } from '../../utilities/car-shop/car-shop-types'
import CarShopPrintHeader from '../car-shop/car-shop-print-header'
import { CarShopSinglePageData } from '../../utilities/create-pages/fetch-car-shop-data'
import Columns from '../ui/columns'
import { CraftEntry, Language, Tenant } from '../../utilities/general/types'
import Footer from '../core/footer'
import GlobalStyles from '../core/global-styles'
import Header from '../core/header'
import OuterWrapper from '../core/outer-wrapper'
import { PageContext } from './default-page'
import PageWrapper from '../ui/page-wrapper'
import preparePageData from '../../utilities/craft-data/prepare-page-data'
import React from 'react'
import SEO from '../core/seo'
import styled from 'styled-components'
import updateShopCarMetaTags from '../../utilities/car-shop/update-shop-car-meta-tags'
import { CarDetailContext, CarShopContext } from '../car-shop/car-shop-context'
import { tenantSupportsRedrive } from '../../utilities/car-shop/redrive'

interface CarShopDetailsContext extends PageContext {
    availableShopOfferTypes: string[]
    carShopLabels: CarShopLabels
    carShopSinglePageData: CarShopSinglePageData
    carShopType: CarShopType
    shopCar: ShopCar
}

const CarShopDetailPage = ({
    pageContext,
    location,
}: {
    pageContext: CarShopDetailsContext
    location: any
}): JSX.Element => {
    const { availableShopOfferTypes, carShopLabels, carShopSinglePageData, carShopType, currentLocale, shopCar } =
        pageContext

    const currentEntry: CraftEntry = {
        ...carShopSinglePageData,
        typeHandle: 'carShopSinglePage',
        slug: `/${carShopType === CarShopType.OCCASIONS ? 'occasions' : 'inventories'}/${shopCar.id}`,
    }

    const { footerProps, headerProps, seoProps } = preparePageData({ ...pageContext, currentEntry })
    const shopCarSeoProps = updateShopCarMetaTags({ carShopLabels, locale: currentLocale, seoProps, shopCar })

    const queryStringParams = new URLSearchParams(location.search)
    const offerType = queryStringParams.get('offerType') as ShopOfferEnum

    return (
        <OuterWrapper currentLocale={currentLocale}>
            <CarShopContext.Provider
                value={{
                    labels: carShopLabels,
                    supportsRedrive: tenantSupportsRedrive(currentLocale.tenant as Tenant),
                    language: currentLocale.language as Language,
                    tenant: currentLocale.tenant as Tenant,
                }}
            >
                <CarDetailContext.Provider value={{ car: shopCar, offerType }}>
                    <SEO {...shopCarSeoProps} />
                    <GlobalStyles />
                    <PrintStyles>
                        <PageWrapper>
                            <Header {...headerProps} />
                            <main>
                                <CarShopBackground>
                                    <Columns>
                                        <CarShopBackLink
                                            availableShopOfferTypes={availableShopOfferTypes}
                                            carShopType={carShopType}
                                            locale={currentLocale}
                                            location={location}
                                            carShopLabels={carShopLabels}
                                        />
                                    </Columns>
                                    <Columns>
                                        <CarShopGrid>
                                            <CarShopPrintHeader carShopSinglePageData={carShopSinglePageData} />
                                            <CarShopDetailImage carShopLabels={carShopLabels} shopCar={shopCar} />
                                            <CarShopDetailSpecs />
                                            <CarShopFeatures
                                                carShopSinglePageData={carShopSinglePageData}
                                                shopCar={shopCar}
                                            />
                                            <CarShopAdditionalInfo carShopSinglePageData={carShopSinglePageData} />
                                        </CarShopGrid>
                                    </Columns>
                                </CarShopBackground>
                            </main>
                            <Footer {...footerProps} />
                        </PageWrapper>
                    </PrintStyles>
                </CarDetailContext.Provider>
            </CarShopContext.Provider>
        </OuterWrapper>
    )
}

export default CarShopDetailPage

const CarShopGrid = styled.div`
    @media screen and (min-width: 56rem) {
        display: grid;
        grid: repeat(3, auto) / 18fr minmax(23rem, 11fr);
        grid-gap: 1.25rem;

        > :nth-child(2) {
            grid-area: 1 / 2 / span 2 / span 1;
        }

        > :nth-child(3) {
            grid-area: 2 / 1 / span 2 / span 1;
        }
    }

    @media screen and (min-width: 63rem), print {
        grid-template-rows: repeat(2, auto);

        > :nth-child(1n) {
            grid-area: auto;
        }
    }
`

/* --- PRINT STYLES --- */

const PrintStyles = styled.div`
    @media print {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translate3d(0, 0, 0);

        > * > :first-child,
        > * > :last-child,
        main > * > :first-child,
        [class^='car-shop-additional-info'],
        [class^='car-shop-detail-specs__Banner'],
        [class^='car-shop-price-table__MoreInformationButton'],
        [class^='car-shop-detail-image'],
        [class^='car-shop-detail-specs__BottomContainer'],
        [class^='car-shop-price-table__AccompanyingText'],
        [class^='header__'],
        [class^='header__HeaderSpacer'] {
            visibility: hidden;
            display: none;
        }

        [class^='columns__Container'] {
            padding: 0;
        }
        [class^='car-shop-detail-specs__SpecsList'] {
            > :nth-child(9) {
                visibility: hidden;
                display: none;
            }
            > :nth-child(10) {
                visibility: hidden;
                display: none;
            }
        }

        [class^='detail-page__CarShopGrid'] {
            display: grid;
            grid: repeat(3, auto) / calc(40% - 1.25rem) 60%;
            grid-gap: 1.25rem;

            > *:not(:last-child) {
                border: 2px solid var(--color-green);
            }

            > :last-child {
                visibility: hidden;
                display: none;
                grid-area: 1 / 1 / span 1 / span 2;
            }

            > :first-child {
                grid-area: 2 / 1 / span 1 / span 1;
                margin: 0;
            }

            > :nth-child(2) {
                grid-area: 2 / 2 / span 2 / span 1;
                margin: 0;
            }

            > :nth-child(3) {
                grid-area: 3 / 1 / span 1 / span 1;
                visibility: hidden;
                display: none;
            }
        }

        [class^='car-shop-features__Box'] {
            img {
                visibility: hidden;
                display: none;
            }

            h2 {
                margin-inline-start: 30px;
                display: block;
                text-align: left;
            }

            ul {
                margin-inline-start: 30px;
                display: block;
                text-align: left;
            }
        }
    }
`
