import { Language } from '../general/types'
import { RedriveCarCondition, ShopOfferEnum } from './car-shop-types'

export type RedriveSpecificLabels = {
    fromLabel: string
    edition100DLabel: string
    moreKmLabel: string
    fromKmPerYearLabel: string
    perMonthLabel: string
    perYearLabel: string
    additionalCostLabel: string
    requestQuoteLabel: string
    [RedriveCarCondition.AS_NEW]?: string
    [RedriveCarCondition.USED]?: string
    [RedriveCarCondition.HEAVILY_USED]?: string
    extraInfo?: string
    footnote?: {
        [ShopOfferEnum.fol]?: string
        [ShopOfferEnum.privateLease]?: string
    }
}

export type RedriveLabelMap = {
    [key in Language]?: RedriveSpecificLabels
}

const labels: RedriveLabelMap = {
    [Language.NL]: {
        fromLabel: 'vanaf',
        edition100DLabel: '100D uitvoering',
        moreKmLabel: 'Meerprijs per 5000km',
        fromKmPerYearLabel: 'Vanaf 10000km per jaar',
        perMonthLabel: 'per maand',
        perYearLabel: 'per jaar',
        additionalCostLabel: 'Meerprijs',
        requestQuoteLabel: 'Vraag nu offerte aan',
        [RedriveCarCondition.AS_NEW]: 'ZGAN',
        [RedriveCarCondition.USED]: 'LG',
        [RedriveCarCondition.HEAVILY_USED]: 'ZG',
        extraInfo: '*Alle genoemde prijzen zijn exclusief BTW',
        footnote: {
            [ShopOfferEnum.fol]: 'Alle genoemde prijzen zijn exclusief BTW',
            [ShopOfferEnum.privateLease]: 'Alle genoemde prijzen zijn inclusief BTW',
        },
    },
    [Language.FR]: {
        fromLabel: 'à partir de',
        fromKmPerYearLabel: 'A partir de 10.000km par an',
        moreKmLabel: '5000km supplémentaires',
        edition100DLabel: 'Version 100D',
        perMonthLabel: 'par mois',
        perYearLabel: 'par an',
        additionalCostLabel: 'Supplément',
        requestQuoteLabel: 'Demandez une offre',
        [RedriveCarCondition.AS_NEW]: 'ZGAN',
        [RedriveCarCondition.USED]: 'LG',
        [RedriveCarCondition.HEAVILY_USED]: 'ZG',
    },
    [Language.DE]: {
        fromLabel: 'ab',
        fromKmPerYearLabel: 'Ab 10000km pro Jahr',
        edition100DLabel: '100D Edition',
        moreKmLabel: 'Aufpreis pro 5000km',
        perMonthLabel: 'netto mtl.',
        perYearLabel: 'pro Jahr',
        additionalCostLabel: 'Aufpreis',
        requestQuoteLabel: 'Fordern Sie ein Angebot an',
        [RedriveCarCondition.AS_NEW]: 'neuwertig',
        [RedriveCarCondition.USED]: 'leicht gebraucht',
        [RedriveCarCondition.HEAVILY_USED]: 'sichtbar gebraucht',
    },
}

export const getRedriveLabels = (language: Language): RedriveSpecificLabels => {
    if (labels[language]) {
        return labels[language] as RedriveSpecificLabels
    }

    return labels[Language.NL] as RedriveSpecificLabels
}
