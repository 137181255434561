import { CarShopLabels, ShopOfferPurchase, ShopCar } from '../../utilities/car-shop/car-shop-types'
import formatWithCurrencySymbol from '../../utilities/general/format-with-currency-symbol'
import { getLocaleString, Locale } from '../../utilities/general/locale'
import { makeTitleCase } from '../../utilities/general/labels'
import React, { useContext } from 'react'
import styled from 'styled-components'

interface CarShopPriceTablePurchaseProps {
    carShopLabels: CarShopLabels
    offer: ShopOfferPurchase
    shopCar: ShopCar
}

const CarShopPriceTablePurchase = ({ carShopLabels, offer, shopCar }: CarShopPriceTablePurchaseProps): JSX.Element => {
    const locale = useContext(Locale)
    const localeString = getLocaleString(locale)

    const registrationDate = new Date(shopCar.firstRegistrationDate)
    let findioLink =
        'https://www.findio.nl/voor-auto/dealercalculatie?utm_source=dealer&utm_medium=mistergreen&subagent=170000000647&catp=K&utm_content=fintab&stmak=Tesla'
    findioLink += `&stmod=${makeTitleCase(shopCar.model.name)}&stmon=${registrationDate.getMonth() + 1}`
    findioLink += `&styea=${registrationDate.getFullYear}&stmil=${shopCar.currentMileage}`
    findioLink += `&ken=${shopCar.licensePlate}&cost=${offer.priceExVAT}&img=${shopCar.mainImageUrl}`

    return (
        <Container>
            <PurchasePrice>
                <span>
                    {formatWithCurrencySymbol(offer.priceExVAT, localeString, {
                        hardcodeCurrency: 'EUR',
                        displayAsFloat: false,
                    })}
                </span>
                <span>
                    {carShopLabels.priceTablePurchaseText.replace(
                        /\{\{\s*priceIncVat\s*\}\}/g,
                        formatWithCurrencySymbol(offer.priceIncVAT, localeString, {
                            hardcodeCurrency: 'EUR',
                            displayAsFloat: false,
                        })
                    )}
                </span>
            </PurchasePrice>
            {locale.country === 'nl' && (
                <FindioButton href={findioLink}>{carShopLabels.priceTableFinancingButtonText}</FindioButton>
            )}
        </Container>
    )
}

export default CarShopPriceTablePurchase

const Container = styled.div`
    text-align: center;
`

const PurchasePrice = styled.p`
    max-width: 16rem;
    margin: 0 auto;

    span {
        display: block;

        &:first-child {
            color: var(--color-green-dark);
            font-family: var(--font-headings);
            font-size: var(--font-size-extra-large);
            font-weight: var(--font-weight-bold);
            line-height: 1.1;

            @media (min-width: 26rem) {
                font-size: var(--font-size-extra-extra-large);
            }
        }

        &:last-child {
            color: var(--color-black-light);
            font-size: var(--font-size-extra-small);

            @media (min-width: 26rem) {
                font-size: var(--font-size-small);
            }
        }
    }
`

const FindioButton = styled.a`
    display: inline-block;
    background-color: #60b4c2;
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 2.5rem;
    border-radius: 0.25rem;
    margin: 1rem auto 0;
    box-shadow: 0 0.0625rem 0.5rem 0 rgba(0, 0, 0, 0.08);

    &:hover {
        box-shadow: none;
    }
`
