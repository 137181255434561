import { CarShopSinglePageData } from '../../utilities/create-pages/fetch-car-shop-data'
import React from 'react'
import { ShopCar } from '../../utilities/car-shop/car-shop-types'
import styled, { css } from 'styled-components'

// eslint-disable-next-line
// @ts-ignore
import imageFallback from '../../assets/tesla-logo.png'

interface CarShopFeaturesProps {
    carShopSinglePageData: CarShopSinglePageData
    shopCar: ShopCar
}

const CarShopFeatures = ({ carShopSinglePageData, shopCar }: CarShopFeaturesProps): JSX.Element => {
    return (
        <Container>
            <Box>
                <Image src={shopCar.exteriorImageUrl || imageFallback} alt="" loading="lazy" />
                <TextContainer>
                    <Heading>{carShopSinglePageData.exteriorHeading}</Heading>
                    <List>{shopCar.exterior && shopCar.exterior.map((item, idx) => <li key={idx}>{item}</li>)}</List>
                </TextContainer>
            </Box>
            <Box isReversed={true}>
                <Image src={shopCar.interiorImageUrl || imageFallback} alt="" loading="lazy" />
                <TextContainer>
                    <Heading>{carShopSinglePageData.interiorHeading}</Heading>
                    <List>{shopCar.interior && shopCar.interior.map((item, idx) => <li key={idx}>{item}</li>)}</List>
                </TextContainer>
            </Box>
            <Box>
                <Image src={shopCar.featuresImageUrl || imageFallback} alt="" loading="lazy" />
                <TextContainer>
                    <Heading>{carShopSinglePageData.featuresHeading}</Heading>
                    <List>
                        {shopCar.accessories && shopCar.accessories.map((item, idx) => <li key={idx}>{item}</li>)}
                    </List>
                </TextContainer>
            </Box>
        </Container>
    )
}

export default CarShopFeatures

const Container = styled.ul`
    list-style: none;
    padding: 0;
    margin: 1.25rem 0 0;

    @media (min-width: 56rem) {
        margin: 0;
    }
`

const Box = styled.li<{ isReversed?: boolean }>`
    background-color: var(--color-white);
    margin: 0 0 1.25rem;

    &:last-of-type {
        margin: 0;
    }

    @media (min-width: 28rem) {
        display: grid;
        grid-template-columns: repeat(2, 50%);

        ${props =>
            props.isReversed &&
            css`
                > :first-child {
                    grid-area: 1 / 2 / span 1 / span 1;
                }

                > :last-child {
                    grid-area: 1 / 1 / span 1 / span 1;
                    text-align: right;
                }
            `}
    }
`

const Image = styled.img`
    height: auto;
    max-height: 14rem;
    width: 100%;
    object-fit: contain;
    object-position: top;

    @media (min-width: 28rem) {
        height: 100%;
    }
`

const TextContainer = styled.div`
    padding: 0.375rem 1.25rem 1.125rem;

    @media (min-width: 28rem) {
        padding: 1rem 1.25rem;
    }

    @media (min-width: 63rem) {
        padding: 1.75rem;
    }
`

const Heading = styled.h2`
    font-size: var(--font-size-large);
    margin: 0 0 0.75rem;
`

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        font-size: var(--font-size-small);
        margin: 0.25rem 0 0;

        @media (min-width: 63rem) {
            font-size: var(--font-size);
        }
    }
`
