import { ShopOfferEnum, ShopOfferFOL, ShopOfferPrivateLease } from '../../utilities/car-shop/car-shop-types'
import CarShopPriceTablePurchase from './car-shop-price-table-purchase'
import CarShopPriceTableR2S from './car-shop-price-table-r2s'
import CarShopPriceTableTakeover from './car-shop-price-table-takeover'
import getPrettyOfferTypeName from '../../utilities/car-shop/get-pretty-offer-type-name'
import MaterialSelect from '../ui/material-select'
import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import CarShopPriceTableRedrive from './car-shop-price-table-redrive'
import { CarDetailContext, CarShopContext } from './car-shop-context'
import { Locale } from '../../utilities/general/locale'
import { isRedriveOffer } from '../../utilities/car-shop/redrive'
import CarShopPriceTableMatrix from './car-shop-price-table-matrix'
import { RequestRedriveQuoteButton } from './car-shop-detail-request-quote'

const CarShopPriceTable = (): JSX.Element => {
    const locale = useContext(Locale)
    const { labels: carShopLabels, tenant } = useContext(CarShopContext)
    const { offerType: contextOfferType, car: shopCar } = useContext(CarDetailContext)
    const [offerType, setOfferType] = useState(contextOfferType || ShopOfferEnum.fol)

    const availableOfferTypes = shopCar.offers.map(offer => ({
        name: getPrettyOfferTypeName(carShopLabels, offer.__typename),
        value: offer.__typename,
    }))

    const isReadyToShareOffer = offerType === ShopOfferEnum.ready2Share
    const showRedriveOffer = isRedriveOffer(tenant, offerType)

    const isRegularOffer = !isReadyToShareOffer && !showRedriveOffer

    if (shopCar.contractTakeover) {
        return <CarShopPriceTableTakeover carShopLabels={carShopLabels} shopCar={shopCar} />
    }

    return (
        <>
            {isRegularOffer && (
                <MaterialSelect
                    currentValue={offerType as string}
                    label={carShopLabels.priceTableOfferTypeLabel}
                    onChange={value => setOfferType(value as ShopOfferEnum)}
                    selectables={availableOfferTypes}
                />
            )}
            {shopCar.offers.map(offer => {
                const mappedOfferType = offer.__typename as ShopOfferEnum
                const standardLeaseTypes = [ShopOfferEnum.fol, ShopOfferEnum.privateLease]

                if (!isReadyToShareOffer && standardLeaseTypes.includes(mappedOfferType)) {
                    return (
                        <PriceTableContainer key={offer.__typename} isVisible={offerType === offer.__typename}>
                            {showRedriveOffer ? (
                                <CarShopPriceTableRedrive />
                            ) : (
                                <CarShopPriceTableMatrix
                                    carShopLabels={carShopLabels}
                                    offer={offer as ShopOfferFOL | ShopOfferPrivateLease}
                                    shopCar={shopCar}
                                />
                            )}
                        </PriceTableContainer>
                    )
                } else if (offer.__typename === 'CarShop_ShopOfferReady2Share' && isReadyToShareOffer) {
                    return (
                        <PriceTableContainer key={offer.__typename} isVisible={offerType === offer.__typename}>
                            <CarShopPriceTableR2S />
                        </PriceTableContainer>
                    )
                } else if (
                    offer.__typename === 'CarShop_ShopOfferPurchase' &&
                    offerType.includes('CarShop_ShopOfferPurchase')
                ) {
                    return (
                        <PriceTableContainer key={offer.__typename} isVisible={offerType === offer.__typename}>
                            <CarShopPriceTablePurchase carShopLabels={carShopLabels} offer={offer} shopCar={shopCar} />
                        </PriceTableContainer>
                    )
                }
            })}
            {isReadyToShareOffer && (
                <MoreInformationButton
                    href="https://www.mistergreen.nl/ready2share#mg_form_629c1e5e-6dd3-47b1-a9ae-756e4ce3c864"
                    target="_blank"
                >
                    Vraag nu offerte aan
                </MoreInformationButton>
            )}
            {showRedriveOffer && <RequestRedriveQuoteButton />}
            {/* NOTE: added another check instead of ternary, because after refreshing page it would render this button even if you are in Ready2Share offerType */}
            {!isReadyToShareOffer && !showRedriveOffer && (
                <>
                    <MoreInformationButton
                        href={carShopLabels.priceTableButtonUrl + '?occasion_license_plate=' + shopCar.licensePlate}
                        target="_blank"
                    >
                        {locale.hasCheckout ? carShopLabels.checkoutButtonText : carShopLabels.priceTableButtonText}
                    </MoreInformationButton>
                    <AccompanyingText>
                        {locale.hasCheckout
                            ? carShopLabels.checkoutButtonAccompanyingText
                            : carShopLabels.priceTableButtonAccompanyingText}
                    </AccompanyingText>
                </>
            )}
        </>
    )
}

export default CarShopPriceTable

const PriceTableContainer = styled.div<{ isVisible?: boolean }>`
    display: none;
    min-height: 14rem;
    margin: 1.375rem 0 1.625rem;

    ${props =>
        props.isVisible &&
        css`
            display: flex;
            align-items: center;
            flex-direction: column;

            > * {
                width: 100%;
            }
        `}
`

const MoreInformationButton = styled.a`
    display: block;
    background-image: var(--gradient-green);
    color: var(--color-white);
    text-align: center;
    padding: 1rem 2rem;
    border-radius: 3rem;
    transition: opacity 0.1s ease-in-out;

    &:hover {
        opacity: 0.8;
    }
`

const AccompanyingText = styled.p`
    color: var(--color-grey-extra-dark);
    font-size: var(--font-size-small);
    text-align: center;
    line-height: 1.45;
    margin: 1rem 0 0;
`
